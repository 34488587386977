import Header from "./header";
import Pet from "./pet";
import Screen from "./screen";
import { createContext, useState, useEffect } from "react";
import { ethers } from "ethers";

export const WalletContext = createContext(null);
const opChainId = 0xa;

const Contents = () => {

  const[index, setIndex] = useState(0);
  
  const [connected, setConnected] = useState(false);
  const [optimism, setOptimism] = useState(false);
  const [installed, setInstalled] = useState(false);
  const [deposit, setDeposit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState("");
  const [textButton, setTextButton] = useState("Connect wallet");
  const [multiButtonText, setMultiButtonText] = useState("Connect wallet");
  const [provider, setProvider] = useState();
  const [contract, setContract] = useState();
  const [gwlpTokenContract, setGwlpTokenContract] = useState();
  const [quoteTokenContract, setQuoteTokenContract] = useState();

  const contractAbi = ["function tokenPrice() external view returns (uint)", "function deposit(uint depositAmount) external", "function withdraw(uint amount) external returns (bool transferPending)", "function GWLP() external view returns(address)", "function quoteToken() external view returns(address)"]

  const erc20Abi = ["function balanceOf(address account) external view returns (uint256)", "function decimals() external view returns(uint8)", "function allowance(address owner, address spender) external view returns (uint256)", "function approve(address spender, uint256 amount) external returns (bool)"];

  window.addEventListener("load", function () {
    if (!(window.ethereum && window.ethereum.isMetaMask)) {
      setInstalled(false);
      setTextButton("MetaMask not installed");
      setMultiButtonText("MetaMask not installed");
    } else {
      setInstalled(true);
      setTextButton("Connect wallet");
      setMultiButtonText("Connect wallet");
    }
  });

  const AccountChangedHandler = (accounts) => {
    let display;
    setAccount(accounts[0]);
    if (accounts.length > 0) {
      display = accounts[0].substring(0, 6) + "..." + accounts[0].substring(38);
      setTextButton(display);
    } else {
      setTextButton("Connect wallet");
      setMultiButtonText("Connect wallet");
      setConnected(false);
      setAccount("");
    }
  };

  useEffect(() => {
    window.ethereum?.on("accountsChanged", AccountChangedHandler);
    return () => {
      window.ethereum?.removeListener("accountsChanged", AccountChangedHandler);
    };
  });

  const ChainChangedHandler = (chainId) => {
    if (connected) {
      let display;
      if (chainId == opChainId) {
        setOptimism(true);
        display = account.substring(0, 6) + "..." + account.substring(38);
        setTextButton(display);
        setMultiButtonText("Introduce amount");
      } else {
        setOptimism(false);
        setTextButton("Change to OP");
        setMultiButtonText("Change to OP");
      }
    }
  };


  useEffect(() => {
    window.ethereum?.on("chainChanged", ChainChangedHandler);
    return () => {
      window.ethereum?.removeListener("chainChanged", ChainChangedHandler);
    };
  });

  const ConnectWallet = async () => {
    window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then(async (accounts) => {
        setConnected(true);
        
        await changeToOp()
      
        window.ethereum.request({ method: "eth_chainId" }).then((chainId) => {
          if (chainId == opChainId) {
            let aux = new ethers.providers.Web3Provider(window.ethereum);
            setProvider(aux);
            setOptimism(true);
            AccountChangedHandler(accounts)
            setMultiButtonText("Introduce amount")

            const contractObject = new ethers.Contract("0x0d9606fF84A568cd04dFdf412c8c835A7f2Ff712", contractAbi, aux);
            const quoteTokenObject = new ethers.Contract("0x7f5c764cbc14f9669b88837ca1490cca17c31607", erc20Abi, aux);
            const gwlpTokenObject = new ethers.Contract("0x7ca353632692190e9b7e88cf0113288b06a5db4b", erc20Abi, aux);

            setContract(contractObject)
            setGwlpTokenContract(gwlpTokenObject)
            setQuoteTokenContract(quoteTokenObject)

          } else setOptimism(false);
        });
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  const addOPNetwork = async () => {
    try {
      const result = await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0xA",
            rpcUrls: ["https://mainnet.optimism.io"],
            chainName: "Optimism",
            nativeCurrency: {
              name: "Ether",
              symbol: "ETH",
              decimals: 18,
            },
            blockExplorerUrls: ["https://explorer.optimism.io"],
          },
        ],
      });
    } catch (error) {
      // console.log(error);
    }
  };

  const changeToOp = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: "0xA" }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        await addOPNetwork()
      }
      else {
        // console.log("Network didn't change");
        setMultiButtonText('Change to OP');
        setTextButton('Change to OP');
      }
    } 
  }

  return (
    <div className="Contents">
      <WalletContext.Provider
        value={{
          connected,
          setConnected,
          optimism,
          setOptimism,
          installed,
          setInstalled,
          deposit,
          setDeposit,
          loading,
          setLoading,
          account,
          setAccount,
          textButton,
          setTextButton,
          multiButtonText,
          setMultiButtonText,
          provider,
          setProvider,
          index,
          setIndex,
          contract,
          gwlpTokenContract,
          quoteTokenContract
        }}
      >
        <Header connect={ConnectWallet} op={changeToOp} />
        <div className="divInvest">
          <span style={{ color: "#F0E400" }} className="investDashboard">
            Invest
          </span>
          <svg
            className="vector8"
            width="70"
            height="2"
            viewBox="0 0 70 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="70" height="2" rx="1" fill="#F0E400" />
          </svg>
          <svg
            className="vector9"
            width="18"
            height="9"
            viewBox="0 0 18 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 9L0.339745 -1.63133e-06L17.6603 -1.17124e-07L9 9Z"
              fill="#F0E400"
            />
          </svg>
          <span
            style={{ color: "#FFFFFF", marginLeft: 100 }}
            className="investDashboard"
          >
            Dashboard
          </span>
        </div>
        <svg
        className="vector"
        width="1140"
        height="404"
        viewBox="0 0 1140 404"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M395.888 138.506C228.104 123.113 29.7263 219.033 -48.4897 268.917C-60.9488 343.03 -104.902 829.377 -75 899.5C-37.6224 987.153 953.643 907.483 1099 931C1244.36 954.517 1475.68 102.162 1130.98 18.7845C786.275 -64.593 605.617 157.747 395.888 138.506Z"
          fill="url(#paint0_linear_160_73)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_160_73"
            x1="631"
            y1="43"
            x2="697"
            y2="479"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#84937D" />
            <stop offset="0.230453" stopColor="#7B8A75" />
            <stop offset="0.467036" stopColor="#CAD0BE" />
            <stop offset="0.651431" stopColor="#F1F2EE" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
        <Screen connect={ConnectWallet} op={changeToOp} />
        <Pet />
      </WalletContext.Provider>
    </div>
  );
};

export default Contents;
