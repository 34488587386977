/* global BigInt */
import Cartera from "./cartera";
import React, { useState, useContext, useCallback, useEffect } from "react";
import Selection from "./selection";
import { WalletContext } from "./contents";
import { ethers } from "ethers";

const BackgroundApp = ({ connect, op }) => {
  const {
    account,
    connected,
    setConnected,
    optimism,
    setOptimism,
    deposit,
    setDeposit,
    installed,
    setInstalled,
    multiButtonText,
    setMultiButtonText,
    loading,
    setLoading,
    provider,
    setProvider,
    contract,
    gwlpTokenContract,
    quoteTokenContract
  } = useContext(WalletContext);
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [updateOutput, setUpdateOutput] = useState(true);

  const contractAddr = "0x0d9606fF84A568cd04dFdf412c8c835A7f2Ff712"

  const switchButton = () => {
    if(connected && optimism) {
      setDeposit(!deposit);
      setInput("");
      setOutput("");
      setMultiButtonText("Introduce amount");
    }
  };

  const calculateOutput = async (value) => {
    if (provider != null) {
      setLoading(true);

      let quoteTokenDecimals = await quoteTokenContract.decimals();
      let gwlpTokenDecimals = await gwlpTokenContract.decimals();
      let price = await contract.tokenPrice() / 10**quoteTokenDecimals;

      if (deposit) {
        try {
          let quoteTokenBalance = BigInt(await quoteTokenContract.balanceOf(account));
          let quoteAllowance = BigInt(await quoteTokenContract.allowance(account, contractAddr))
          let qty = BigInt(value*10**quoteTokenDecimals)

          if (quoteTokenBalance >= qty && quoteAllowance <= qty) setMultiButtonText("Approve USDC");
          else if(quoteTokenBalance < qty) setMultiButtonText("Insufficient USDC balance");
          else setMultiButtonText("Deposit");

          if (value != "" && value != 0) setOutput(value / price);
          else {
            setOutput("");
            setMultiButtonText("Introduce amount");
          }
        } catch (e) {
            setOutput("");
            setMultiButtonText("Introduce amount");
        }
      } else if (!deposit) {
        try {
          let gwlpBalance = BigInt(await gwlpTokenContract.balanceOf(account));
          let gwlpAllowance = BigInt(await gwlpTokenContract.allowance(account, contractAddr))
          let qty = BigInt(value*10**gwlpTokenDecimals)

          if (gwlpBalance >= qty && gwlpAllowance <= qty) setMultiButtonText("Approve GWLP");
          else if(gwlpBalance < qty) setMultiButtonText("Insufficient GWLP balance");
          else setMultiButtonText("Withdraw");

          if (value != "" && value != 0) setOutput(value * price);
          else {
            setOutput("");
            setMultiButtonText("Introduce amount");
          }
        } catch(e) {
            setOutput("");
            setMultiButtonText("Introduce amount");
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if(!optimism || !connected) {setInput(""); setOutput("");}
  }, [optimism, connected]);

  useEffect(() => {
    setInput("");
    setOutput("");
  }, [account]);

  useEffect(() => {
    if(optimism && connected) calculateOutput(input);
  }, [input]);

  return (
    <div className="BackgroundApp">
      <div className="Input">
        <input
          className="textarea"
          inputMode="decimal"
          type="number"
          placeholder="0"
          value={input}
          disabled={!connected || !optimism}
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <Selection gwlp={!deposit} />
      </div>
      <div className="botonSwitch" >
        <svg
          onClick={switchButton}
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="25" cy="25" r="25" fill="#F5F5F5" />
          <circle cx="25" cy="25" r="20" fill="#F0E400" />
          <path
            d="M31 21.3482L25.7395 15.5696C25.3938 15.1899 25.221 15 25 15C24.779 15 24.6062 15.1899 24.2605 15.5696L19 21.3482M19 27.7573L24.2605 33.5359C24.6062 33.9157 24.779 34.1055 25 34.1055C25.221 34.1055 25.3938 33.9157 25.7395 33.5359L31 27.7573"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="Output">
        <input
          className="textarea2"
          inputMode="decimal"
          type="number"
          placeholder="0"
          disabled={true}
          value={output}
        ></input>
        <Selection gwlp={deposit} />
      </div>
      <Cartera connect={connect} op={op} input={input} output={output}/>
    </div>
  );
};

export default BackgroundApp;
