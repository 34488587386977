import Actions from "./actions";
import Connect from "./connect";
import Language from "./language";

const HeaderBotones = ({connect,op}) => {

    return (
        <div className="HeaderBotones">
            <Connect connect={connect} op={op}/>
            <Language/>
            <Actions/>
        </div>
    )
}

export default HeaderBotones;