
const ScreenHeader = () => {

    return (
        <div className="ScreenHeader">
            <div className="elipses">
                <svg className="elipse1"  width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7.5" cy="7.5" r="7.5" fill="white" fillOpacity="0.5"/>
                </svg>
                <svg className="elipse2" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7.5" cy="7.5" r="7.5" fill="white" fillOpacity="0.5"/>
                </svg>
                <svg className="elipse3"  width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7.5" cy="7.5" r="7.5" fill="white" fillOpacity="0.5"/>
                </svg>
            </div>
        </div>
    )
}

export default ScreenHeader;