import { useCallback, useContext, useState } from 'react';
import {WalletContext} from './contents'
import Modal from './modal';
import styled from "styled-components";

const Cartera = ({connect, op, input, output}) => {

    const {connected, deposit, setDeposit, loading, setLoading, multiButtonText, setMultiButtonText, optimism, installed, setInstalled, setIndex, contract, gwlpTokenContract, quoteTokenContract} = useContext(WalletContext);

    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);
    const [modal4, setModal4] = useState(false);

    const contractAddr = "0x0d9606fF84A568cd04dFdf412c8c835A7f2Ff712"

    const MultiButton = useCallback(() =>
    {
        if(installed) {
            if(!connected) connect();
            else if(!optimism) op();
            else if(deposit) {
                if(multiButtonText == 'Deposit') {setModal1(!modal1); setIndex(-1)}
                else if (multiButtonText == 'Approve USDC') {
                    //Aprove input qty
                }
            }
            else if (!deposit) {
                if (multiButtonText == 'Withdraw') {setModal1(!modal1); setIndex(-1)}
                else if (multiButtonText == 'Approve GWLP') {
                    //Aprove input qty
                }
            }
        } else {
            window.open('https://metamask.io/download/')
        }
    }, [connected, optimism, deposit, installed, multiButtonText]);

    let titleOp = (
        <>
            <svg style={{position: 'relative', top: 1}} xmlns="http://www.w3.org/2000/svg" width="20px" viewBox="0 0 122 122">
                <g id="optimism-logo" transform="translate(-219 -213)">
                    <g id="optimism-logo-circle" transform="translate(219 213)">
                    <circle id="Ellipse_11" data-name="Ellipse 11" cx="61" cy="61" r="61" fill="#ff0420"/>
                    <path id="Path_139" data-name="Path 139" d="M113.533,178.026a14.656,14.656,0,0,1-8.924-2.563,8.762,8.762,0,0,1-3.432-7.413,16.433,16.433,0,0,1,.229-2.471q.595-3.3,1.693-7.917,3.112-12.585,16.062-12.585a15.966,15.966,0,0,1,6.315,1.19,9.6,9.6,0,0,1,4.393,3.478,9.333,9.333,0,0,1,1.6,5.492,16.288,16.288,0,0,1-.229,2.425q-.687,4.073-1.647,7.917-1.6,6.269-5.537,9.381Q120.123,178.026,113.533,178.026Zm.641-6.59a6.5,6.5,0,0,0,4.348-1.51,8.424,8.424,0,0,0,2.608-4.622q1.053-4.3,1.6-7.505a10.5,10.5,0,0,0,.183-1.968q0-4.165-4.347-4.164a6.681,6.681,0,0,0-4.393,1.51,8.573,8.573,0,0,0-2.563,4.622q-.824,3.066-1.647,7.505a9.791,9.791,0,0,0-.183,1.922Q109.78,171.436,114.174,171.436Z" transform="translate(-70.332 -100.849)" fill="#fff"/>
                    <path id="Path_140" data-name="Path 140" d="M205.3,178.612a.97.97,0,0,1-.778-.32,1.1,1.1,0,0,1-.137-.824l6.315-29.746a1.31,1.31,0,0,1,.5-.824,1.4,1.4,0,0,1,.87-.32h12.173a14.148,14.148,0,0,1,8.146,2.105,6.9,6.9,0,0,1,3.112,6.087,10.955,10.955,0,0,1-.275,2.38,12.39,12.39,0,0,1-4.622,7.78q-3.432,2.517-9.427,2.517H215l-2.105,10.022a1.311,1.311,0,0,1-.5.824,1.4,1.4,0,0,1-.869.32Zm16.2-17.482a5.451,5.451,0,0,0,3.341-1.052,4.942,4.942,0,0,0,1.922-3.02,8.022,8.022,0,0,0,.137-1.373,2.543,2.543,0,0,0-.778-2.014,3.836,3.836,0,0,0-2.654-.732h-5.491l-1.739,8.191Z" transform="translate(-142.055 -101.892)" fill="#fff"/>
                    </g>
                </g>
            </svg>
            <span style={{position: 'relative', top: -3}}> Optimism</span>
        </>
    );

    let gwlpToken = (
        <>
            <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="20" height="20" rx="10" fill="#E6007E"/>
                <path d="M7 17L12.4901 9.41373C12.6781 9.15387 12.9794 9 13.3002 9H15.0766C15.8861 9 16.3602 9.91176 15.8951 10.5744L13.0881 14.5744C12.9009 14.8412 12.5955 15 12.2696 15H9.10526" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                <path d="M8.805 3L4.15179 9.4127C3.67208 10.0738 4.14436 11 4.96115 11H7.45" stroke="white" strokeWidth="2" strokeLinecap="round"/>
            </svg>
        </>
    );

    let usdcToken = (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" data-name="86977684-12db-4850-8f30-233a7c267d11" viewBox="0 0 2000 2000">
                <path d="M1000 2000c554.17 0 1000-445.83 1000-1000S1554.17 0 1000 0 0 445.83 0 1000s445.83 1000 1000 1000z" fill="#2775ca"/>
                <path d="M1275 1158.33c0-145.83-87.5-195.83-262.5-216.66-125-16.67-150-50-150-108.34s41.67-95.83 125-95.83c75 0 116.67 25 137.5 87.5 4.17 12.5 16.67 20.83 29.17 20.83h66.66c16.67 0 29.17-12.5 29.17-29.16v-4.17c-16.67-91.67-91.67-162.5-187.5-170.83v-100c0-16.67-12.5-29.17-33.33-33.34h-62.5c-16.67 0-29.17 12.5-33.34 33.34v95.83c-125 16.67-204.16 100-204.16 204.17 0 137.5 83.33 191.66 258.33 212.5 116.67 20.83 154.17 45.83 154.17 112.5s-58.34 112.5-137.5 112.5c-108.34 0-145.84-45.84-158.34-108.34-4.16-16.66-16.66-25-29.16-25h-70.84c-16.66 0-29.16 12.5-29.16 29.17v4.17c16.66 104.16 83.33 179.16 220.83 200v100c0 16.66 12.5 29.16 33.33 33.33h62.5c16.67 0 29.17-12.5 33.34-33.33v-100c125-20.84 208.33-108.34 208.33-220.84z" fill="#fff"/>
                <path d="M787.5 1595.83c-325-116.66-491.67-479.16-370.83-800 62.5-175 200-308.33 370.83-370.83 16.67-8.33 25-20.83 25-41.67V325c0-16.67-8.33-29.17-25-33.33-4.17 0-12.5 0-16.67 4.16-395.83 125-612.5 545.84-487.5 941.67 75 233.33 254.17 412.5 487.5 487.5 16.67 8.33 33.34 0 37.5-16.67 4.17-4.16 4.17-8.33 4.17-16.66v-58.34c0-12.5-12.5-29.16-25-37.5zM1229.17 295.83c-16.67-8.33-33.34 0-37.5 16.67-4.17 4.17-4.17 8.33-4.17 16.67v58.33c0 16.67 12.5 33.33 25 41.67 325 116.66 491.67 479.16 370.83 800-62.5 175-200 308.33-370.83 370.83-16.67 8.33-25 20.83-25 41.67V1700c0 16.67 8.33 29.17 25 33.33 4.17 0 12.5 0 16.67-4.16 395.83-125 612.5-545.84 487.5-941.67-75-237.5-258.34-416.67-487.5-491.67z" fill="#fff"/>
            </svg>
        </>
    );

    let titleSwap;
    let buttonSwap;
    let nameToken1;
    let nameToken2;
    let token1;
    let token2;

    if(multiButtonText == 'Withdraw'){
        titleSwap = 'Review Withdraw';
        buttonSwap = 'Withdraw';
        nameToken1 = 'GW.LP';
        nameToken2 = 'USDC';
        token1 = gwlpToken;
        token2 = usdcToken;
    } else if (multiButtonText == 'Deposit') {
        titleSwap = 'Review Deposit';
        buttonSwap = 'Deposit';
        nameToken1 = 'USDC';
        nameToken2 = 'GW.LP';
        token1 = usdcToken;
        token2 = gwlpToken;
    }
    
    return (
        <div className="Cartera">
            <button className="cartera" onClick={() => {MultiButton()}} disabled={loading}>{multiButtonText}</button>
            {loading && <img className="loadingIcon" src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"/>}

            {/*Modal 1*/}

            <Modal state={modal1} changeState={setModal1} title={titleSwap} justify="center">
                <ContenidoTop>
                    <p>You pay</p>
                    <ContenidoBottom style={{marginBottom: 0}}>
                        <h1>{input} {nameToken1}</h1>
                        {token1}
                    </ContenidoBottom>
                    <p>${input}</p>
                    <p>You receive</p>
                    <ContenidoBottom style={{marginBottom: 0}}>
                        <h1>{output} {nameToken2}</h1>
                        {token2}
                    </ContenidoBottom>
                    <p style={{marginBottom: 0}}>${input}</p>
                </ContenidoTop>
                <ContenidoBottom style={{paddingTop: 20, borderTop: '1px solid #D1D0CE'}}>
                    <h3>Exchange rate</h3>
                    <p>1 USDT = 1,810.99 ETH</p>
                </ContenidoBottom>
                <ContenidoBottom>
                    <h3>Network fee</h3>
                    <p>~$0.34</p>
                </ContenidoBottom>
                <ContenidoBottom>
                    <h3>Price impact</h3>
                    <p>-0.300%</p>
                </ContenidoBottom>
                <ContenidoBottom>
                    <h3>Minimum received</h3>
                    <p>0.180918 USDT</p>
                </ContenidoBottom>
                <Boton onClick={() => {setModal2(!modal2); setModal1(!modal1)}}>{buttonSwap}</Boton>
            </Modal>

            {/*Modal 2*/}
            
            <Modal state={modal2} changeState={setModal2} title={titleOp} justify='space-between' background='rgba(210, 210, 210, 0.2)' height='475px'>
                <ContenidoTop style={{alignItems: 'center'}}>
                    <img style={{margin: '0 auto', marginTop: 20}} src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif" width='120px'/>  
                    <h2>Confirm transaction in wallet</h2>
                    <p style={{fontSize: 20, marginBottom: 90}}>Swapping {input} {nameToken1} for {output} {nameToken2}</p>
                </ContenidoTop>
                <Boton onClick={() => {setModal3(!modal3); setModal2(!modal2)}}>Close</Boton>
            </Modal>

            {/*Modal 3*/}
            
            <Modal state={modal3} changeState={setModal3} title='Error' justify='space-between' height='350px'>
                <ContenidoTop style={{alignItems: 'center'}}>
                    <svg style={{marginTop: 20}} xmlns="http://www.w3.org/2000/svg" width="110" height="110" fill="#F75D59" className="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                        <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                        <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                    </svg>
                    <h2>Transaction rejected</h2>
                </ContenidoTop>
                <Boton onClick={() => {setModal4(!modal4); setModal3(!modal3)}}>Dismiss</Boton>
            </Modal>

            {/*Modal 4*/}
            
            <Modal state={modal4} changeState={setModal4} title={titleOp} justify='space-between' background='rgba(210, 210, 210, 0.2)' height='350px'>
                <ContenidoTop style={{alignItems: 'center'}}>
                    <svg style={{marginTop: 20}} xmlns="http://www.w3.org/2000/svg" width="110" height="110" fill="#64E986" className="bi bi-check-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                    </svg>
                    <h2>Success</h2>
                    <p style={{fontSize: 20}}>Swap exactly {input} {nameToken1} for {output} {nameToken2}</p>
                    <Enlace>View on Explorer</Enlace>
                    <BotonCartera>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-wallet2" viewBox="0 0 16 16">
                            <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"/>
                        </svg>
                        <span>Add to Wallet</span>
                        <svg style={{position: 'relative', top: 3}}  width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="20" height="20" rx="10" fill="#E6007E"/>
                            <path d="M7 17L12.4901 9.41373C12.6781 9.15387 12.9794 9 13.3002 9H15.0766C15.8861 9 16.3602 9.91176 15.8951 10.5744L13.0881 14.5744C12.9009 14.8412 12.5955 15 12.2696 15H9.10526" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                            <path d="M8.805 3L4.15179 9.4127C3.67208 10.0738 4.14436 11 4.96115 11H7.45" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </BotonCartera>
                </ContenidoTop>
                <Boton onClick={() => {setModal4(!modal4); setIndex(0)}}>Close</Boton>
            </Modal>
        </div>
    )
}

export default Cartera;

const Enlace = styled.a`
    text-decoration: none;
    color: #56A5EC;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 17px;

    &:visited {
        color: #56A5EC;
    }
`;

const BotonCartera = styled.button`
    display: block;
    width: 250px;
    height: 50px;
	padding: 10px;
	border-radius: 10px;
	color: #fff;
	border: none;
	background: rgba(210, 210, 210, 0.2);
	cursor: pointer;
    font-size: 18px;
	font-family: 'Roboto', sans-serif;
	font-weight: 600;
	transition: .3s ease all;

    &:hover {
		background: rgba(210, 210, 210, 0.4);
	}

    span {
        position: relative;
        margin: 0 15px;
        top: -3px;
    }
`;

const Boton = styled.button`
	display: block;
    width: 100%;
    height: 70px;
	padding: 10px 30px;
	border-radius: 25px;
	color: #fff;
	border: none;
	background: #1766DC;
	cursor: pointer;
    font-size: 24px;
	font-family: 'Roboto', sans-serif;
	font-weight: 600;
	transition: .3s ease all;

	&:hover {
		background: #0066FF;
	}
`;

const ContenidoTop = styled.div`
	display: flex;
	flex-direction: column;
    margin-bottom: 25px;

    h2 {
        font-weight: 500;
        font-size: 25px;
        color: #FFF;
        margin-bottom: 0;
        margin-top: 40px;
    }

	p {
		font-size: 18px;
		margin-bottom: 12px;
        color: #CCC;
        font-weight: 400;
	}

`;


const ContenidoBottom = styled.div`
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: space-between; 
    margin-bottom: 10px;
    
    h3 {
        font-weight: 500;
        font-size: 18px;
        color: #CCC;
    }

    h1 {
        font-weight: 600;
        font-size: 45px;
        color: #FFF;
        letter-spacing: 2px;
    }

    p {
		font-size: 18px;
        color: #FFF;
        font-weight: 500;
	}

    svg {
        width: 40px;
        height: 40px;
    }

    
`;