import { useContext, useState } from 'react';
import {WalletContext} from './contents'

const Connect = ({connect,op}) => {
    
    const {connected, setConnected, optimism, setOptimism, installed, setInstalled, account, setAccount, textButton, setTextButton} = useContext(WalletContext);

    const ButtonOnClick = () => {
        if(!connected) connect();
        else if(!optimism) op();
    }

    return (
        <div>
            <button style={{width: 'fit-content'}} className="Connect" onClick={ButtonOnClick} disabled= {!installed || (connected && optimism)}> {textButton} </button>
        </div>
    )
}

export default Connect;