import Contents from './contents';

const Background = () => {

    return (

        <div className="Background">
            <div className="Vector">
                <svg width="100%" height="100%" viewBox="0 0 1280 346" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1187.17 126.01C423.269 -185.765 -24.8989 153.695 -153.495 362.397C-2219 2988 856.612 2604.2 1317 2568C1672.95 2487.07 1951.08 437.785 1187.17 126.01Z" fill="url(#paint0_linear_202_3344)"/>
                <defs>
                <linearGradient id="paint0_linear_202_3344" x1="-40.5296" y1="500.822" x2="1298.01" y2="500.822" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FCC194"/>
                <stop offset="1" stopColor="#B99275"/>
                </linearGradient>
                </defs>
                </svg>
            </div>
            <Contents/>
        </div>

)
}

export default Background;