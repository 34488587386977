import React, { useContext } from "react";
import {WalletContext} from './contents'

const Pet = () => {

    const {index} = useContext(WalletContext);

    return (
        <div style={{zIndex: index}} className="Pet">
            <img className="gato" src="../Imagenes/gatoCut.png"/>
        </div>
    )
}

export default Pet;