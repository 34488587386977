import './App.css';
import React from 'react';
import Background from './Components/background';
import FooterApp from './Components/footerApp';

export default function WebApp() {
    return (
        <div className='App'>
        <Background/>
        <FooterApp/>
        </div>
    )
}