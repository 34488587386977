import Block from "./block";
import ScreenHeader from "./screenheader";

const Screen = ({connect,op}) => {

    return (

        <div className="Screen">
            <ScreenHeader/>
            <Block connect={connect} op={op}/>
        </div>

)
}

export default Screen;