import React, { useContext } from "react";
import {WalletContext} from './contents'
import styled from "styled-components";

const Modal = ({children, state, changeState, title = "Title not found", justify, background = "none", width, height}) => {

    const {setIndex} = useContext(WalletContext);

    return (
        <>
            {state && 
            <Overlay>
                <ContenedorModal width={width} height={height}>
                    <EncabezadoModal justify={justify}>
                        <h3 style={{backgroundColor: background}}>{title}</h3>
                    </EncabezadoModal>
                    <BotonCerrar onClick={() => {changeState(false); setIndex(0)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                        </svg>
                    </BotonCerrar>
                    {children}
                </ContenedorModal>
            </Overlay>
            }
        </>
    ); 
}

export default Modal;

const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
`;

const ContenedorModal = styled.div`
    width: ${props => props.width ? props.width : '450px'}; 
    min-height: ${props => props.height ? props.height : '550px'}; 
    background: #262626;
    position: relative;
    border-radius: 25px;
    box-shadow: rgba(100,100,111, 0.2) 0px 7px 29px 0px;
    padding: 20px;
    border: 1px solid #CCC;
`;

const EncabezadoModal = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props => props.justify ? props.justify : 'center'}; 
    
    h3 {
        font-weight: 500;
        font-size: 20px;
        color: #FFF;
        margin-top: 0;
        padding: 3px 8px;
        border-radius: 10px;
    }
`;

const BotonCerrar = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    border: none;
    background: none;
    cursor: pointer;
    transition: .3s ease all;
    border-radius: 5px;
    color: #FFF;

    &:hover {
        background: rgba(0,0,0,.2);
    }

    svg {
        width: 100%;
        height: 100%;
    }
`;
