import BackgroundApp from "./backgroundapp";
import Tool from "./tool";

const Block = ({connect,op}) => {

    return (
        <div className="Block">
            <BackgroundApp connect={connect} op={op}/>
            <Tool/>
        </div>
    )
}

export default Block;