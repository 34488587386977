import React from 'react';
import { BrowserRouter, HashRouter, Link, Route, Routes, Switch } from "react-router-dom";
import WebApp from './WebApp';

function App() {

    return(

      <div>
        <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<WebApp />} />
            </Routes>
        </BrowserRouter>
      </div>
  
    )
  
  

}

export default App;
